import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import Title from './components/Title.vue';
import '@/assets/css/scss.scss' // 引入全局样式

createApp(App).component('Title', Title).use(ElementPlus).use(store).use(router).mount('#app')


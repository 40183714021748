<template>
  <header :style="{ marginTop: isTranlating ? '38px' : '0' }" class="header position-r">
<!--    <div class=" rlogin position-a">-->
<!--      <div class="no-login flex" v-if="username === false">-->
<!--        <a @click="showDialog('login')">登录</a>-->
<!--        <div class="xieg">/</div>-->
<!--        <a @click="showDialog('register')">注册</a>-->
<!--      </div>-->
<!--      <div v-else class="flex">-->
<!--        <div>{{username}}</div>-->
<!--        <div class="tui" @click="logout">登出</div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="container">
      <RouterLink to="/home" class="logo-wrapper">
        <img src="../../public/images/logo.png" alt=""/>
      </RouterLink>
      <el-menu
          router
          class="custom-el-menu"
          style="width: 620px"
          mode="horizontal"
          :router="true"
          :default-active="route.path"
      >
        <el-menu-item index="/home">首页</el-menu-item>
        <el-sub-menu>
          <template #title>海外营销平台</template>
          <el-menu-item
              v-for="item in menus"
              class="custom-sub-menu-wrapper"
              :index="item.to"
              :key="item.to"
          >
            <img :src="item.img" alt=""/>
            {{ item.title }}
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3">
          <template #title>代投放服务</template>
          <el-menu-item index="/product/dtc">DTC 品牌服务</el-menu-item>
          <el-menu-item index="/product/btb">BTB 客户服务</el-menu-item>
          <el-menu-item index="/product/value">增值服务</el-menu-item>
        </el-sub-menu>
        <el-menu-item @click="dialogVisible = true">在线购买</el-menu-item>
        <el-menu-item index="/product/contact">关于我们</el-menu-item>
      </el-menu>
<!--      <div class="telephone">WhatsApp: +85262729547</div>-->
      <div class=" rlogin">
        <div class="no-login flex" v-if="username === false">
          <a @click="showDialog('login')">登录</a>
          <div class="xieg">/</div>
          <a @click="showDialog('register')">注册</a>
        </div>
        <div v-else class="flex">
          <div>{{username}}</div>
          <div class="tui" @click="logout">登出</div>
        </div>
      </div>
    </div>
  </header>
  <el-dialog v-model="dialogVisible" title="立即购买" width="800px" class="dialogVisible">
    <div class="p-4">
      <div class="xiaotishi flex flex-a-c">
        购买小提示：购买后资金将直接用于账户消费，请确认金额无误。若有疑问请联系客服。
      </div>
      <!-- 购买金额 -->
      <div class="flex items-center flex flex-a-c">
        <div class="whitespace-nowrap mr-4">请输入您想购买的金额：</div>
        <el-input v-model="amount" placeholder="请输入" class="flex-1">
          <template #append>HKD</template>
        </el-input>
      </div>


<!--       折合人民币-->
<!--      <div class="text-red-500 text-center mt-2 flex flex-j-c">-->
<!--        折合人民币：¥{{ rmbAmount }} (零元整)-->
<!--      </div>-->
      <!-- 推广套餐 -->
      <ul class="flex tuiguang flex-j-sb">
        <li class="flex flex-col flex-j-c flex-a-c el-card cursor-p" :class="tgListIndex==index?'select':''" @click="ontgList(item.amount,index)" v-for="(item,index) in tgList">
          <span>{{ item.amount }}</span>
          <span>{{ item.name }}</span>
        </li>
      </ul>

      <!-- 支付方式 -->
      <div class="grid grid-cols-3 gap-4 mt-4 flex flex-j-sb zffs">
        <el-card
            class="cursor-pointer mor"
            :class="{ 'border-primary': paymentMethod === 'huifu' }"
            @click="paymentMethod = 'huifu'">
          <div class="flex flex-j-c">
            <svg t="1742738180871" class="icon rmbsvg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7632" width="200" height="200"><path d="M753.493333 391.850667v-27.989334h21.12v27.989334c20.48 1.365333 36.522667 7.168 48.810667 17.066666 13.994667 10.922667 22.528 29.013333 25.941333 53.589334h-36.181333c-2.048-14.677333-7.509333-25.258667-16.042667-31.402667a44.416 44.416 0 0 0-22.528-8.192v78.165333c25.258667 9.557333 39.936 15.36 43.349334 16.725334 23.552 13.312 35.498667 32.085333 35.498666 56.661333 0 24.234667-9.216 42.325333-26.965333 54.613333-13.653333 8.874667-30.72 13.994667-51.882667 15.36v30.037334h-21.162666v-30.037334c-23.210667-1.365333-40.96-8.192-53.248-20.138666-14.336-13.653333-22.186667-34.133333-24.234667-62.122667h36.864c1.365333 18.432 5.802667 31.744 13.994667 39.594667 6.144 6.144 15.018667 9.898667 26.624 11.264v-83.285334l-38.570667-14.336c-23.893333-12.629333-35.84-31.402667-35.84-56.661333 0-22.186667 8.533333-39.253333 25.6-51.2 12.970667-9.557333 29.013333-14.677333 48.810667-15.701333z m21.12 145.408v75.776c10.922667-1.365333 19.797333-4.437333 27.306667-9.216a32.725333 32.725333 0 0 0 14.677333-27.989334c0-15.701333-11.946667-27.989333-35.84-36.181333-2.048-0.682667-4.096-1.706667-6.144-2.389333z m-21.162666-44.032V422.570667c-9.557333 0.682667-17.408 3.413333-23.210667 7.509333-9.557333 6.144-13.994667 15.701333-13.994667 28.672 0 13.994667 11.946667 25.258667 35.84 34.133333 0.341333 0 0.682667 0.341333 1.365334 0.341334zM599.509333 396.288h47.104l-107.861333 104.789333L662.314667 640h-49.834667l-98.986667-114.688-31.744 30.378667V640h-37.205333V396.288h37.205333v117.76l117.76-117.76zM194.56 396.288h37.205333v102.741333h127.658667V396.288h37.205333V640h-37.205333v-109.226667H231.765333V640H194.56V396.288z" p-id="7633"></path></svg>
            <div class="text-center">UnionPay/Visa&Master</div>
          </div>

        </el-card>
        <el-card
            class="cursor-pointer mor"
            :class="{ 'border-primary': paymentMethod === 'shouxin' }"
            @click="paymentMethod = 'shouxin'"
        >
          <div class="flex flex-j-c">
            <svg t="1742738180871" class="icon rmbsvg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7632" width="200" height="200"><path d="M753.493333 391.850667v-27.989334h21.12v27.989334c20.48 1.365333 36.522667 7.168 48.810667 17.066666 13.994667 10.922667 22.528 29.013333 25.941333 53.589334h-36.181333c-2.048-14.677333-7.509333-25.258667-16.042667-31.402667a44.416 44.416 0 0 0-22.528-8.192v78.165333c25.258667 9.557333 39.936 15.36 43.349334 16.725334 23.552 13.312 35.498667 32.085333 35.498666 56.661333 0 24.234667-9.216 42.325333-26.965333 54.613333-13.653333 8.874667-30.72 13.994667-51.882667 15.36v30.037334h-21.162666v-30.037334c-23.210667-1.365333-40.96-8.192-53.248-20.138666-14.336-13.653333-22.186667-34.133333-24.234667-62.122667h36.864c1.365333 18.432 5.802667 31.744 13.994667 39.594667 6.144 6.144 15.018667 9.898667 26.624 11.264v-83.285334l-38.570667-14.336c-23.893333-12.629333-35.84-31.402667-35.84-56.661333 0-22.186667 8.533333-39.253333 25.6-51.2 12.970667-9.557333 29.013333-14.677333 48.810667-15.701333z m21.12 145.408v75.776c10.922667-1.365333 19.797333-4.437333 27.306667-9.216a32.725333 32.725333 0 0 0 14.677333-27.989334c0-15.701333-11.946667-27.989333-35.84-36.181333-2.048-0.682667-4.096-1.706667-6.144-2.389333z m-21.162666-44.032V422.570667c-9.557333 0.682667-17.408 3.413333-23.210667 7.509333-9.557333 6.144-13.994667 15.701333-13.994667 28.672 0 13.994667 11.946667 25.258667 35.84 34.133333 0.341333 0 0.682667 0.341333 1.365334 0.341334zM599.509333 396.288h47.104l-107.861333 104.789333L662.314667 640h-49.834667l-98.986667-114.688-31.744 30.378667V640h-37.205333V396.288h37.205333v117.76l117.76-117.76zM194.56 396.288h37.205333v102.741333h127.658667V396.288h37.205333V640h-37.205333v-109.226667H231.765333V640H194.56V396.288z" p-id="7633"></path></svg>
            <div class="text-center">WeChat</div>
          </div>

        </el-card>
        <el-card
            class="cursor-pointer mor"
            :class="{ 'border-primary': paymentMethod === 'personal' }"
            @click="paymentMethod = 'personal'"
        >

          <div class="flex flex-j-c">
            <svg t="1742738180871" class="icon rmbsvg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7632" width="200" height="200"><path d="M753.493333 391.850667v-27.989334h21.12v27.989334c20.48 1.365333 36.522667 7.168 48.810667 17.066666 13.994667 10.922667 22.528 29.013333 25.941333 53.589334h-36.181333c-2.048-14.677333-7.509333-25.258667-16.042667-31.402667a44.416 44.416 0 0 0-22.528-8.192v78.165333c25.258667 9.557333 39.936 15.36 43.349334 16.725334 23.552 13.312 35.498667 32.085333 35.498666 56.661333 0 24.234667-9.216 42.325333-26.965333 54.613333-13.653333 8.874667-30.72 13.994667-51.882667 15.36v30.037334h-21.162666v-30.037334c-23.210667-1.365333-40.96-8.192-53.248-20.138666-14.336-13.653333-22.186667-34.133333-24.234667-62.122667h36.864c1.365333 18.432 5.802667 31.744 13.994667 39.594667 6.144 6.144 15.018667 9.898667 26.624 11.264v-83.285334l-38.570667-14.336c-23.893333-12.629333-35.84-31.402667-35.84-56.661333 0-22.186667 8.533333-39.253333 25.6-51.2 12.970667-9.557333 29.013333-14.677333 48.810667-15.701333z m21.12 145.408v75.776c10.922667-1.365333 19.797333-4.437333 27.306667-9.216a32.725333 32.725333 0 0 0 14.677333-27.989334c0-15.701333-11.946667-27.989333-35.84-36.181333-2.048-0.682667-4.096-1.706667-6.144-2.389333z m-21.162666-44.032V422.570667c-9.557333 0.682667-17.408 3.413333-23.210667 7.509333-9.557333 6.144-13.994667 15.701333-13.994667 28.672 0 13.994667 11.946667 25.258667 35.84 34.133333 0.341333 0 0.682667 0.341333 1.365334 0.341334zM599.509333 396.288h47.104l-107.861333 104.789333L662.314667 640h-49.834667l-98.986667-114.688-31.744 30.378667V640h-37.205333V396.288h37.205333v117.76l117.76-117.76zM194.56 396.288h37.205333v102.741333h127.658667V396.288h37.205333V640h-37.205333v-109.226667H231.765333V640H194.56V396.288z" p-id="7633"></path></svg>
            <div class="text-center">Alipay</div>
          </div>

        </el-card>
      </div>
      <!-- 按钮 -->
      <div class="text-center mt-4 zfbn-box">
        <el-button type="primary" :disabled="!amount" @click="handlePay" class="zfbn">立即支付</el-button>
      </div>
      <div class="xianer">*单笔限额:200.000HKD</div>

      <!-- 购买规则 -->
      <div class="text-sm text-gray-600 mt-4 guize">
        <p>支付规则</p>
        <p>1、购买金额将用于广告投放</p>
        <p>2、其他细则请联系 lijxu309@gmail.com</p>
      </div>


    </div>
  </el-dialog>

  <el-dialog
      v-model="dialogVisible2"
      :title="isLogin ? '登录' : '注册'"
      width="420px"
      class="auth-dialog dialogVisible2"
  >
    <el-form :model="form" :rules="rules" ref="formRef" label-width="80px" class="box-b form">
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"/>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="form.password" type="password" placeholder="请输入密码" show-password/>
      </el-form-item>
      <el-form-item v-if="!isLogin" label="确认密码" prop="confirmPassword">
        <el-input v-model="form.confirmPassword" type="password" placeholder="请再次输入密码" show-password/>
      </el-form-item>
      <div class="flex flex-j-c errorMsg position-r">
        <span class="position-a span1">{{errorMsg}}</span>
      </div>

    </el-form>

    <template #footer>
      <div  class="flex flex-col">
        <el-button class="bt1" @click="dialogVisible2 = false">取消</el-button>
        <el-button class="bt2" type="primary" @click="submitForm">{{ isLogin ? '登录' : '注册' }}</el-button>
      </div>

    </template>
  </el-dialog>

</template>
<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {watch} from "vue";
import {ElMessage} from "element-plus";
import axios from "axios";

const dialogVisible = ref(false);
const amount = ref("");
const rmbAmount = ref("0.00");
const paymentMethod = ref("huifu");
const exchangeRate = 0.92328;
const username = localStorage.getItem('username') ?? false;

/*推套餐广*/
const tgList = ref([
  {amount:'10000HKD',name:'推广套餐'},
  {amount:'20000HKD',name:'推广套餐'},
  {amount:'30000HKD',name:'推广套餐'},
])
const tgListIndex= ref(0)

const ontgList = (amountNew,index) => {
  tgListIndex.value = index;
  amount.value = amountNew
}

const dialogVisible2 = ref(false);
const isLogin = ref(true);
const formRef = ref(null);
const form = ref({
  email: "",
  password: "",
  confirmPassword: "",
});

// 验证规则
const rules = {
  email: [
    {required: true, message: "请输入邮箱", trigger: "blur"},
    {type: "email", message: "请输入正确的邮箱格式", trigger: "blur"}
  ],
  password: [
    {required: true, message: "请输入密码", trigger: "blur"},
    {min: 6, message: "密码长度不能少于6位", trigger: "blur"}
  ],
  confirmPassword: [
    {required: true, message: "请确认密码", trigger: "blur"},
    {
      validator: (rule, value, callback) => {
        if (value !== form.value.password) {
          callback(new Error("两次密码输入不一致"));
        } else {
          callback();
        }
      },
      trigger: "blur"
    }
  ]
};

// 显示弹窗
const showDialog = (type) => {
  isLogin.value = type === "login";
  form.value = {email: "", password: "", confirmPassword: ""};
  dialogVisible2.value = true;
  errorMsg.value = ''

};

// 提交表单
const submitForm = async () => {
  formRef.value.validate( async (valid) => {
    if (valid) {
      if (isLogin.value){
        await onLoginBut();
      }else {
        await onRegister()
      }
      console.log(isLogin.value ? "登录成功" : "注册成功", form.value);

      // dialogVisible2.value = false;
    } else {
      console.log("表单验证失败");
    }
  });
};
const errorMsg = ref('');
const onLoginBut = async (res)=>{
  errorMsg.value = ''
  try {
    const response = await axios.post('https://ad.fonr.ai/api/user/login', {
      account: form.value.email,
      password: form.value.password,
    })
    if (response.data.code == 0){
      errorMsg.value = response.data.msg
    }else {
      const data = response.data.data.userinfo
      localStorage.setItem('username', data.username)
      localStorage.setItem('token', data.token)
      dialogVisible2.value = false;

      ElMessage({
        message: 'Login succeeded.',
        type: 'success',
      })
      setTimeout(() => {
        window.location.reload();
        // router.push('/')
      }, 1500)



    }

  } catch (error) {
    console.error('请求失败:', error)
  }
}

const onRegister = async (res)=>{
  errorMsg.value = ''
  try {
    const response = await axios.post('https://ad.fonr.ai/api/user/register', {

      email: form.value.email,
      password: form.value.password,

    })
    if (response.data.code == 0){
      errorMsg.value = response.data.msg
    }else {
      const data = response.data.data.userinfo
      localStorage.setItem('username', data.username)
      localStorage.setItem('token', data.token)
      dialogVisible2.value = false;

      ElMessage({
        message: 'registered successfully.',
        type: 'success',
      })
      setTimeout(() => {
        window.location.reload();
        // router.push('/')
      }, 1500)
    }

  } catch (error) {
    console.error('请求失败:', error)
  }
}
const logout = async ()=>{
  try {
    const response = await axios.post('https://ad.fonr.ai/api/user/logout', {},{
      headers: {
        token: localStorage.getItem('token') // 假设 token 存在 localStorage
      }
    })
    if (response.data.code == 0){
      localStorage.removeItem('username')
      localStorage.removeItem('token')
    }else {
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      ElMessage({
        message: 'logout succeeded.',
        type: 'success',
      })
      setTimeout(() => {
        window.location.reload();
      }, 1500)
    }

  } catch (error) {
    console.error('请求失败:', error)
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    setTimeout(() => {
      window.location.reload();
    }, 1500)
  }
}


watch(amount, (newVal) => {
  if (newVal && !isNaN(Number(newVal))) {
    rmbAmount.value = (Number(newVal) * exchangeRate).toFixed(2);
  } else {
    rmbAmount.value = "0.00";
  }
});

const handlePay = () => {
  console.log("支付成功");
  dialogVisible.value = false;
};

const openview1 = () => {
  // window.open('https://www.baidu.com')
  window.open('https://www.baidu.com', '_blank', 'width=800,height=600')
}

const route = useRoute();
console.log(route);

const menus = [
  {
    to: "/product/google",
    title: "Google",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/google.png",
  },
  {
    to: "/product/facebook",
    title: "Facebook",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/facebook.png",
  },
  {
    to: "/product/bing",
    title: "Bing",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/bing.png",
  },
  {
    to: "/product/linkedin",
    title: "LinkedIn",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/linkedin.png",
  },
  {
    to: "/product/twitter",
    title: "Twitter",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/twitter.png",
  },
  {
    to: "/product/tiktok",
    title: "Tiktok",
    img: "https://assets-lovead-com.oss-cn-shanghai.aliyuncs.com/website/menu/tiktok.png",
  },
];
const isTranlating = ref(false);
onMounted(() => {
  window.addEventListener("languageChanged", (event) => {
    isTranlating.value = event.detail;
  });
});
</script>

<style scoped lang="less">
header {
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(44, 114, 255, 0.05);
  height: 64px;
  left: 0;
  line-height: 64px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
  transition: top 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  z-index: 111;
  display: flex;

  .container {
    display: flex;
    height: 100%;
    margin: 0 auto;
    // width: 1080px;

    align-items: center;

    .logo-wrapper {
      display: inline-block;
      height: 100%;
      margin-right: 10px;
      vertical-align: middle;

      img {
        height: 100%;
      }
    }

    .custom-el-menu {
      width: 550px;
      height: 100%;
      border-bottom: 0;

      .el-menu-item {
        border-bottom: 0;
      }
    }

    .telephone {
      color: #2f6aff;
      font-size: 20px;
      line-height: 35px;
      margin-right: 20px;
      letter-spacing: 1px;
      font-weight: 500;
    }
  }
}
</style>
<style>
.custom-sub-menu-wrapper {
  display: flex;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.el-dialog__footer{
  padding-top:0 !important;
}
</style>
<!--在线充值-->
<style>
.dialogVisible {
  .el-dialog {
    padding: 10px 0 !important;
  }

  .el-dialog__header.show-close {
    padding-left: 20px;
  }

  .cursor-pointer {
    width: 32%;
  }

  .xiaotishi {
    font-size: 16px;
    height: 80px;
    background-color: red;
    color: #fff;
    padding: 16px;
  }

  .items-center {
    margin: 20px;
  }

  .text-gray-500 {
    margin-left: 40px;
  }

  .text-red-500 {
    color: red;
  }

  .zffs {
    padding: 20px;
  }

  .xianer {
    margin: 20px;
    color: red;
  }

  .zfbn-box {
    margin: 20px;

    .zfbn {
      height: 50px;
      width: 100%;
    }
  }

  .guize {
    margin: 20px;
  }

  .text-sm p {
    margin-top: 10px;
  }

  .mor {
    cursor: pointer;
  }

  .border-primary {
    border: 1px solid #26ccff !important;
  }

  .rmbsvg {
    width: 34px;
    height: 34px;
  }

  .text-center {
    margin-left: 2px;
    line-height: 34px;
  }
  .tuiguang{
    padding: 20px;
    width: 100%;
    li{
      width: 32%;
      box-shadow: var(--el-box-shadow-light);
      padding: var(--el-card-padding);
    }

    .select{
      border: 1px solid #364eff !important;
    }
  }

}


</style>
<!--登录注册-->
<style scoped>
.rlogin {
  right: 200px;
  top: 0px;
  .no-login{
    .xieg {
      margin: 0 2px;
    }

    > a {
      cursor: pointer;
    }

    > a:hover {
      color: #83bdbf;
    }
  }
  .tui{
    margin-left: 10px;
    cursor: pointer;
  }
  .tui:hover {
    color: #83bdbf;

  }

}

/* 按钮居中 */
.auth-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 50px;
}

/* 美化弹窗 */
.auth-dialog {
  border-radius: 20px;
  overflow: hidden;
}

/* 美化表单 */
.el-form-item {
  margin-bottom: 18px;
}

.el-input {
  border-radius: 6px;
}

/* 按钮优化 */
.el-button {
  width: 100%;
  border-radius: 6px;
}

/* 过渡动画 */
.auth-dialog .el-overlay {
  animation: fade-in-scale 0.3s ease-in-out;
}

@keyframes fade-in-scale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.auth-dialog{
  .form{
    padding-right: 20px;
  }
  .bt1{
    margin-top: 4px;
    margin-left: 30px;
    width: calc(100% - 40px);
  }
  .bt2{
    margin: 20px 0 0 30px ;
    width: calc(100% - 40px);
  }
  .errorMsg{
    height: 10px;
    color: red;
    .span1{
      top: -10px;
    }
  }

}
</style>
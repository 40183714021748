<template>
  <footer data-fetch-key="data-v-7852e65e:0" class="footer" data-v-7852e65e="">
    <div class="container-footer" data-v-7852e65e="">
      <div class="footer-content" data-v-7852e65e="">
        <div class="logo" data-v-7852e65e="">
          <img
            width="100%"
            src="../../public/images/foot_logo.png"
            data-v-7852e65e=""
          />
<!--          <div class="ga-content" data-v-7852e65e="">-->
<!--            <p data-v-7852e65e="">Google Ads 顶级代理商</p>-->
<!--            <div-->
<!--              data-agency-id="2632601543"-->
<!--              class="g-partnersbadge"-->
<!--              data-v-7852e65e=""-->
<!--            ></div>-->
<!--            <a href="" target="_blank" data-v-7852e65e="">-->
<!--              点击查看 Google 授权证书-->
<!--            </a>-->
<!--          </div>-->
        </div>
        <div class="nav-wrapper" data-v-7852e65e="">
          <div class="nav-block" data-v-7852e65e="">
            <span class="nav-block-title" data-v-7852e65e="">产品链接</span>
            <div class="nav-item" data-v-7852e65e=""></div>
            <div class="nav-item" data-v-7852e65e="">
              <a href="" target="_blank" data-v-7852e65e="">海外营销平台</a>
            </div>
            <div class="nav-item" data-v-7852e65e="">
              <a href="" target="_blank" data-v-7852e65e="">代投放服务</a>
            </div>
            <div class="nav-item" data-v-7852e65e="">
              <a href="" target="_blank" data-v-7852e65e="">在线购买</a>
            </div>
            <div class="nav-item" data-v-7852e65e="">
              <a href="" target="_blank" data-v-7852e65e="">关于我们</a>
            </div>
          </div>
          <div class="nav-block" data-v-7852e65e="">
            <span class="nav-block-title" data-v-7852e65e="">联系我们</span>
            <div class="nav-item" data-v-7852e65e="">
              客服热线：+85262729547（8:30 - 21:00）
            </div>
            <div class="nav-item" data-v-7852e65e="">
              商务合作：WhatsApp: +85262729547
            </div>
            <div class="nav-item" data-v-7852e65e="">
              联系邮箱：lijxu309@gmail.com
            </div>
            <div class="nav-item" data-v-7852e65e="">
              联系地址：RM A07, 1701-02 NEW TREND CENTRE,
              <div>704 PRINCE EDWARD RDEAST, SAN PO KONG HONG KONG</div>
            </div>
          </div>
        </div>
        <div class="footer-p" data-v-7852e65e="">
          <a href="" target="_blank" data-v-7852e65e=""
            >@2024 COLOURMIX TRADING LIMITED All rights reserved.</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="less" scoped>
footer {
  width: 100%;
  .container-footer {
    background: #0a1e39;
    color: #fff;
    font-size: 14px;
    padding-top: 50px;
    position: relative;
    width: 100%;
    .footer-content {
      width: 1080px;
      margin: 0 auto;
      // width: 1080px;
      .logo {
        float: left;
        margin-left: 20px;
        width: 180px;
        .ga-content {
          margin: 30px 0;
          overflow: hidden;
          width: 180px;
          p {
            font-size: 15px;
            margin-bottom: 10px;
          }
        }
      }
      .attention {
        float: right;
        width: 100px;
        .wechat-ads {
          .follow-us {
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
          .wechat-account {
            font-size: 0;
            .account-item {
              display: inline-block;
              line-height: 17px;
              margin-top: 16px;
              vertical-align: top;
              width: 90px;
              img {
                height: 100px;
                margin-bottom: 8px;
                width: 100px;
              }
              span {
                color: #fff;
                display: inline-block;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                opacity: 0.6;
              }
            }
          }
        }
      }
      .nav-wrapper {
        display: flex;
        justify-content: space-around;
        margin-left: 120px;
        vertical-align: top;
        .nav-block {
          display: inline-block;
          vertical-align: top;
          &:not(:last-child) {
            // margin-right: px;
          }
          .nav-block-title {
            color: #fff;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 16px;
          }
          .nav-item {
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 13px;
            a {
              color: #fff;
              opacity: 0.6;
              position: relative;
              -webkit-text-decoration: none;
              text-decoration: none;
            }
          }
        }
      }
      .foot-link {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 15px auto 0;
        width: 678px;
        a {
          color: #fff;
          margin-left: 15px;
          opacity: 0.6;
        }
      }
      .footer-p {
        color: #666d76;
        font-size: 12px;
        padding: 10px 0 20px;
        text-align: center;
        width: 50%;
        a {
          align-items: center;
          color: #666d76;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
<script setup lang="ts">
</script>